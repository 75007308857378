<template>
  <div class="default-layout">
    <header-component />
    <router-view />
  </div>
</template>

<script>
import HeaderComponent from '@/components/layouts/default/header/Header'
import libraryMixin from '@/mixins/libraryMixin'

export default {
  name: 'Default',
  components: { HeaderComponent },
  mixins: [libraryMixin],
}
</script>

<style lang="sass">
body
  background-color: $color-white
.default-layout
  min-height: 100vh
  display: flex
  flex-direction: column
  flex-wrap: nowrap
</style>
